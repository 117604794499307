/* eslint-disable array-callback-return */
<template>
  <!--学习园地：课程精选：课程详情-->
  <div
    class="CourseDetails"
    :style="{ backgroundImage: 'url(' + require('@/assets/img/course-details-bg.png') + ')' }"
  >
    <!--banner-->
    <!--    <div-->
    <!--      class="banner"-->
    <!--     :style="{ backgroundImage: 'url(' + require('@/assets/img/course-details-bg.png') + ')' }"-->
    <!--    />-->

    <main>
      <div class="nav-box">
        <!--图片-->
        <img alt="" class="banner-img" :src="data.courseCover" />
        <!--内容-->
        <div class="content">
          <div class="title">
            <!--课程名称-->
            {{ data.courseName }}
            <!--分享-->
            <button
              type="text"
              @click="Copy($event)"
              :data-clipboard-text="shareLink"
              id="express_info_right"
              class="share"
            >
              <i class="el-icon-share" />

              <!-- <div class="shareMask">
                <div class="left">
                  <p>微信扫码分享</p>
                  <img src="#" alt="" />
                </div>
                <div class="right">
                  <p class="text-left">链接分享</p>
                  <br />
                  <div>
                    点击
                    <span class="text-primary" @click="Copy">此处</span>
                    复制分享链接
                  </div>
                </div>
              </div> -->
            </button>
          </div>

          <!--内容-->
          <div class="box">
            <ul v-if="data.isFree" class="left" style="margin-right:10px;">
              <li>
                <i class="el-icon-suitcase-1 text-primary"></i>
                <span>{{ classcount }}</span>
                节课
              </li>
            </ul>

            <ul v-if="!data.isFree" class="left">
              <li>
                <i class="el-icon-success text-success"></i>
                支持发票
              </li>

              <li>
                <i class="el-icon-suitcase-1 text-primary" style="margin-right:10px;"></i>
                <span>{{ classcount }}</span>
                节课
              </li>

              <li>
                <i class="el-icon-time text-primary"></i>
                终身有效
              </li>
            </ul>

            <div v-if="!data.isFree" class="right">
              <div class="price-box">
                课程价格：
                <span class="unit">￥</span>
                <span class="price">{{ data.coursePrice }}</span>
              </div>

              <div class="btn-group">
                <el-button
                  type="primary"
                  plain
                  @click="ToBeforePay(data.id)"
                  v-if="data.isBuy === 0"
                >
                  课程购买
                </el-button>
                <el-button
                  type="primary"
                  plain
                  @click="ToStudy(data.courseUserId)"
                  v-if="data.isBuy === 1"
                >
                  进入学习
                </el-button>
              </div>
            </div>

            <div v-if="data.isFree" class="right">
              <div class="btn-group">
                <!-- <button class="Consulting">课程咨询</button> -->
                <el-button type="primary" plain @click="getJoin(data.id)" v-if="data.isBuy === 0">
                  立即报名
                </el-button>
                <el-button
                  type="primary"
                  plain
                  @click="ToStudy(data.courseUserId)"
                  v-if="data.isBuy === 1"
                >
                  进入学习
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--tab页-->
      <div class="main-box">
        <div class="tittle">
          <div @click="tab = 1" :class="tab === 1 ? 'active' : ''">课程介绍</div>
          <div @click="tab = 2" :class="tab === 2 ? 'active' : ''">章节目录</div>
        </div>
      </div>
      <!--tab内容-->
      <div class="tab-content" v-show="tab === 1">
        <div v-html="data.courseDescription" class="Introduce"></div>
      </div>
      <div class="tab-content" v-show="tab === 2">
        <h3 class="mb-20">{{ data.courseName }}</h3>
        <p style="font-size: 13px;">
          <span>{{ chaptersCount }}</span>
          个章节 |
          <span>{{ classcount }}</span>
          个课时
        </p>
        <ul v-for="item in data.chapters" :key="item.id">
          <h4 class="name">{{ item.chapterName }}</h4>
          <li class="bar" v-for="items in item.sections" :key="items.id">
            {{ items.sectionName }}
          </li>
        </ul>
      </div>
    </main>
  </div>
</template>

<script>
import API from '@/api';
import clip from '@/utils/clipboard';
// 剪贴板
export default {
  name: 'CourseDetails',
  data() {
    return {
      VUE_APP_USERCONSOLE: process.env.VUE_APP_USERCONSOLE,
      tab: 1,
      courseUserId: {
        id: ''
      },
      courseId: {},
      data: '',
      // classcount: 0, //课时总数量
      chaptersCount: 0, // 章节总数量
      shareLink: '' // 分享链接
    };
  },
  computed: {
    classcount() {
      let count = 0;
      if (this.data.chapters) {
        // eslint-disable-next-line array-callback-return
        this.data.chapters.map((item) => {
          count += item.sections.length;
        });
      }
      return count;
    }
  },
  created() {
    this.getDetail();
  },
  methods: {
    // 复制功能
    Copy(event) {
      const { id } = this.$route.query;
      const Url = process.env.VUE_APP_PAY;
      this.shareLink = `${Url}?id=${id}`;
      clip(this.shareLink, event);

      // const clipboard = new this.Clipboard('#express_info_right');
      // clipboard.on('success', () => {
      //   this.$message({
      //     duration: 1000,
      //     message: '复制成功',
      //     type: 'success'
      //   });
      //   clipboard.destroy();
      // });
      // clipboard.on('error', () => {
      //   this.$message.error('复制失败');
      // });
    },
    getDetail() {
      this.courseUserId.id = this.$route.query.id;
      API.Detail(this.courseUserId).then((res) => {
        this.data = res.message.data;
        this.chaptersCount = res.message.data.chapters.length;
      });
    },
    // 进入学习
    ToStudy(id) {
      window.open(`${this.VUE_APP_USERCONSOLE}Player?courseUserId=${id}&&courseId=${this.data.id}`);
    },
    // 报名
    getJoin(id) {
      this.courseId.courseId = id;
      API.join(this.courseId).then((res) => {
        if (res.message.success === true) {
          this.$message({
            duration: 1000,
            message: '报名成功',
            type: 'success'
          });
          this.getDetail();
        } else {
          this.$message({
            duration: 1000,
            message: res.message.message,
            type: 'error'
          });
          this.getDetail();
          // console.log(res.message.message);
        }
      });
    },
    // 去付款
    ToBeforePay(id) {
      this.$router.push({
        path: '/BeforePay',
        query: {
          id
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.CourseDetails {
  width: 100%;
  position: relative;
  min-height: 600px;
  background-size: 100% 200px;
  background-position: top;
  background-repeat: no-repeat;
  // 顶部背景
  .banner {
    width: 100%;
    height: 300px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    z-index: -1;
  }
}
.nav-box {
  display: flex;
  width: 100%;
  height: 226px;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0 2px 12px 1px rgba(208, 208, 208, 0.3);

  // 图片
  .banner-img {
    flex: 0 0 auto;
    width: 330px;
    height: 100%;
    margin-right: 15px;
    background: #e3e3e3;

    img {
      width: 100%;
      height: 100%;
    }
  }

  // 内容
  .content {
    flex: 1 1 auto;
    height: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  // 标题
  .title {
    flex: 0 0 auto;
    font-size: 16px;
    font-weight: bold;
    line-height: 26px;
    /*height: 52px;*/
    overflow: hidden;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 10px 0 16px;
    padding-right: 35px;
    position: relative;
    // 分享
    .share {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  //
  .box {
    flex: 1 1 auto;
    position: relative;
    // 价格、按钮
    .right {
      display: flex;
      align-items: center;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 10px;
      // 价格栏
      .price-box {
        font-size: 16px;

        // 价格
        .price {
          font-size: 20px;
          color: #f3a000;
          font-weight: bold;
        }
        // 单位
        .unit {
          color: #f3a000;
          font-weight: bold;
        }
      }

      // 按钮
      .btn-group {
        margin-left: auto;
        button {
          font-size: 14px;
          font-weight: bold;
          border-width: 2px;
          padding: 10px 23px;
          + button {
            margin-left: 20px;
          }
        }
      }
    }
  }
}

.share {
  outline: none;
  border: transparent;
  background: transparent;
  cursor: pointer;
  font-size: 20px;
  position: relative;
}
.shareMask {
  border: 1px solid #dddddd;
  border-radius: 4px;
  background: #ffffff;
  width: 360px;
  height: 160px;
  position: absolute;
  left: -100px;
  display: none;
  font-size: 16px;
  padding: 10px;

  .left {
    float: left;
    text-align: center;
    img {
      width: 100px;
      height: 100px;
      margin-top: 20px;
    }
  }
  .right {
    float: right;
  }
}
.share:hover .shareMask {
  display: block;
}
.box {
  .left {
    display: flex;
    li {
      margin-right: 18px;
      font-size: 16px;
      + li {
        margin-left: 10px;
      }
      // 图标
      i {
        margin-right: 5px;
      }
    }
  }
  .right li {
    width: 250px;
    height: 40px;
    line-height: 40px;
    margin: 5px;
    span {
      color: #f3a100;
      font-size: 24px;
    }
    .Consulting {
      border: 1px solid #f3a100;
      background: #ffffff;
      color: #f3a100;
    }
    .Buy {
      width: 100px;
      height: 40px;
      line-height: 40px;
      border: 1px solid #f3a100;
      background: #f3a100;
      color: #ffffff;
    }
    button {
      width: 100px;
      height: 40px;
      line-height: 40px;
      outline: none;
      border: transparent;
      background: transparent;
      cursor: pointer;
      margin: 0 10px;
    }
  }
}
main {
  width: 924px;
  min-height: 600px;
  margin: 80px auto 0;

  .main-box {
    width: 100%;
    margin-top: 40px;
    .tittle {
      display: flex;
    }
    div {
      width: 200px;
      height: 40px;
      line-height: 40px;
      //margin: 0 10px;
      text-align: center;
      cursor: pointer;
    }
    .tittle > div:hover {
      color: #f3a100;
      border-bottom: 1px solid #f3a100;
    }
  }
}
.tab-content {
  max-width: 924px;
  margin: 30px 0;
  img {
    display: block;
    max-width: 924px !important;
  }
}

.name {
  margin: 20px 0;
  padding: 5px 20px;
  background-color: #fafafa;
}
.bar {
  padding: 10px 20px;
  border-left: 1px dashed #d7d7d7;
}
.tryWatch {
  padding: 10px 20px;
  border-left: 1px dashed #d7d7d7;
  // cursor: pointer;
  // color: #F3A100;
}
.tryWatch:hover {
  padding: 10px 20px;
  border-left: 1px dashed #d7d7d7;
  cursor: pointer;
  color: #f3a100;
}
.active {
  color: #f3a100;
  border-bottom: 1px solid #f3a100;
}
</style>
<style lang="scss">
.tab-content img {
  display: block;
  max-width: 924px !important;
}
.Introduce {
  word-break: break-all;
  a {
    text-decoration: underline;
    color: #551a8b;
  }
}
</style>
